import PropTypes from 'prop-types';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import axios from 'utils/axios';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, Dialog, DialogTitle, Grid, DialogContent, Typography, DialogActions, Image } from '@mui/material';

// project import
import IconButton from 'components/@extended/IconButton';

// assets
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const avatarImage = require.context('assets/images/users', true);

// ==============================|| DIALOG - SIMPLE ||============================== //

const generateSecretRequest = async () => {
  const response = await axios.post('/admin/generateSecret');
  //const { token, user } = response.data;
  return response.data;
};

export default function SimpleDialog({ onClose, selectedValue, open }) {
  const theme = useTheme();
  const [secret, setSecret] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleSubmit = async () => {
    try {
      const data = await generateSecretRequest();
      console.log('data', data);
      setSecret(data?.secret);
      setImageUrl(data?.imageUrl);
    } catch (e) {
      dispatch(
        openSnackbar({
          open: true,
          message: e?.response?.data?.error,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      console.log('error', e?.response?.data?.error);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
      >
        <Grid item>
          <DialogTitle>2FA settings</DialogTitle>
        </Grid>
        <Grid item sx={{ mr: 1.5 }}>
          <IconButton color="secondary" onClick={handleClose}>
            <CloseOutlined />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent dividers>
        <Grid container spacing={1.25} style={{ width: 300, height: 300 }}>
          {imageUrl ? <img src={imageUrl} style={{ width: 300, height: 300 }} alt="qrcode" /> : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} sx={{ mr: 1 }}>
          Generate code
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
};
