// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DatabaseOutlined } from '@ant-design/icons';

// icons
const icons = {
  DatabaseOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const affiliate = {
  roles: ['admin', 'affiliate-manager'],
  id: 'group-Affiliate',
  title: <FormattedMessage id="Affiliate" />,
  icon: icons.DatabaseOutlined,
  type: 'group',
  children: [
    {
      id: 'Affiliates',
      title: <FormattedMessage id="Affiliates" />,
      type: 'item',
      url: '/affiliate/affiliates',
      icon: icons.DatabaseOutlined
    }
  ]
};

export default affiliate;
