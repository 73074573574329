import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project import
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import MainRoutes from './MainRoutes';

// render - landing page
//const PagesLanding = Loadable(lazy(() => import('pages/landing')));
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout />,
      children: [
        {
          path: '/',
          element: <AuthLogin />
        }
      ]
    },
    MainRoutes
  ]);
}
