import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - transactions
const Withdrawals = Loadable(lazy(() => import('pages/transactions/withdrawals')));
const Deposits = Loadable(lazy(() => import('pages/transactions/deposits')));
const Bets = Loadable(lazy(() => import('pages/transactions/bets')));
const Wins = Loadable(lazy(() => import('pages/transactions/wins')));
const Positions = Loadable(lazy(() => import('pages/transactions/positions')));
const Transactions = Loadable(lazy(() => import('pages/transactions/transactions')));
//const DashboardAnalytics = Loadable(lazy(() => import('pages/transactions/analytics')));

//render - chatManagement
const ChatUsers = Loadable(lazy(() => import('pages/chat-management/users')));
const ChatMessages = Loadable(lazy(() => import('pages/chat-management/messages')));

//render - support
const SupportUsers = Loadable(lazy(() => import('pages/support/users')));
const SupportKycs = Loadable(lazy(() => import('pages/support/kycs')));
const Promotions = Loadable(lazy(() => import('pages/support/promotions')));
const Freespins = Loadable(lazy(() => import('pages/support/freespins')));

//render - users
const Users = Loadable(lazy(() => import('pages/users/users')));

// render - dashboard
const DashboardAnalytics = Loadable(lazy(() => import('pages/analytics/dashboard')));

// render - casino
const CasinoGames = Loadable(lazy(() => import('pages/casino/games')));
const CasinoProviders = Loadable(lazy(() => import('pages/casino/providers')));

// render - affiliate
const Affiliates = Loadable(lazy(() => import('pages/affiliate/affiliates')));

// render - promotion
const Buybacks = Loadable(lazy(() => import('pages/promotion/buybacks')));

// render - permission
const Logs = Loadable(lazy(() => import('pages/permission/logs')));
const Team = Loadable(lazy(() => import('pages/permission/team')));

// render - requesr
const Form = Loadable(lazy(() => import('pages/requests/form')));

// pages routing
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'analytics',
          children: [
            {
              path: 'dashboard',
              element: <DashboardAnalytics />
            }
          ]
        },
        {
          path: 'transactions',
          children: [
            {
              path: 'withdrawals',
              element: <Withdrawals />
            },
            {
              path: 'deposits',
              element: <Deposits />
            },
            {
              path: 'bets',
              element: <Bets />
            },
            {
              path: 'wins',
              element: <Wins />
            },
            {
              path: 'positions',
              element: <Positions />
            },
            {
              path: 'transactions',
              element: <Transactions />
            }
          ]
        },
        {
          path: 'chat-management',
          children: [
            {
              path: 'users',
              element: <ChatUsers />
            },
            {
              path: 'messages',
              element: <ChatMessages />
            }
          ]
        },
        {
          path: 'support',
          children: [
            {
              path: 'users',
              element: <SupportUsers />
            },
            {
              path: 'withdrawals',
              element: <Withdrawals noActions />
            },
            {
              path: 'kycs',
              element: <SupportKycs />
            },
            {
              path: 'promotions',
              element: <Promotions />
            },
            {
              path: 'freespins',
              element: <Freespins />
            }
          ]
        },
        {
          path: 'users',
          children: [
            {
              path: 'users',
              element: <Users />
            }
          ]
        },
        {
          path: 'casino',
          children: [
            {
              path: 'games',
              element: <CasinoGames />
            },
            {
              path: 'providers',
              element: <CasinoProviders />
            }
          ]
        },
        {
          path: 'permissions',
          children: [
            {
              path: 'logs',
              element: <Logs />
            },
            {
              path: 'team',
              element: <Team />
            }
          ]
        },
        {
          path: 'promotion',
          children: [
            {
              path: 'buybacks',
              element: <Buybacks />
            }
          ]
        },
        {
          path: 'requests',
          children: [
            {
              path: 'form',
              element: <Form />
            }
          ]
        },
        {
          path: 'affiliate',
          children: [
            {
              path: 'affiliates',
              element: <Affiliates />
            }
          ]
        }
      ]
    },
    {
      path: '/auth',
      element: <CommonLayout />,
      children: [
        {
          path: 'login',
          element: <AuthLogin />
        }
      ]
    },
    {
      path: '/login',
      element: <CommonLayout />,
      children: [
        {
          path: '',
          element: <AuthLogin />
        }
      ]
    }
    // {
    //   path: '/',
    //   element: <CommonLayout />,
    //   children: [
    //     {
    //       path: '/',
    //       element: <AuthLogin />
    //     }
    //   ]
    // }
  ]
};

export default MainRoutes;
