// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UserOutlined } from '@ant-design/icons';

// icons
const icons = {
  UserOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const users = {
  roles: ['chat-moderator', 'admin'],
  id: 'group-chatManagement',
  title: <FormattedMessage id="Chat management" />,
  icon: icons.UserOutlined,
  type: 'group',
  children: [
    {
      id: 'ChatUsers',
      title: <FormattedMessage id="Users" />,
      type: 'item',
      url: '/chat-management/users',
      icon: icons.UserOutlined
    },
    {
      id: 'ChatMessages',
      title: <FormattedMessage id="Messages" />,
      type: 'item',
      url: '/chat-management/messages',
      icon: icons.UserOutlined
    }
  ]
};

export default users;
