// project import
import requests from './requests';
import transactions from './transactions';
import analytics from './analytics';
import chatManagement from './chatManagement';
import support from './support';
import users from './users';
import casino from './casino';
import affiliate from './affiliate';
import promotions from './promotions';
import permissions from './permissions';

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
  items: [requests, analytics, transactions, chatManagement, support, users, casino, affiliate, promotions, permissions]
};

export default menuItems;
