// material-ui
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';

// assets
import { SearchOutlined } from '@ant-design/icons';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}></Box>;

export default Search;
