// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UserOutlined } from '@ant-design/icons';

// icons
const icons = {
  UserOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const users = {
  roles: [],
  id: 'group-users',
  title: <FormattedMessage id="Users" />,
  icon: icons.UserOutlined,
  type: 'group',
  children: [
    {
      id: 'Users',
      title: <FormattedMessage id="Users" />,
      type: 'item',
      url: '/users/users',
      icon: icons.UserOutlined
    }
  ]
};

export default users;
