// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { FormOutlined } from '@ant-design/icons';

// icons
const icons = {
  FormOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const requests = {
  roles: ['admin', 'customer-support', 'affiliate-manager', 'marketing-manager'],
  id: 'group-Requests',
  title: <FormattedMessage id="Requests" />,
  icon: icons.FormOutlined,
  type: 'group',
  children: [
    {
      id: 'Requests',
      title: <FormattedMessage id="Requests" />,
      type: 'item',
      url: '/requests/form',
      icon: icons.FormOutlined
    }
  ]
};

export default requests;
