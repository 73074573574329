// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { LineChartOutlined, IdcardOutlined, DatabaseOutlined, BankOutlined } from '@ant-design/icons';

// icons
const icons = {
  LineChartOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  BankOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const transactions = {
  roles: ['customer-support', 'admin'],
  id: 'group-transactions',
  title: <FormattedMessage id="Transactions" />,
  icon: icons.IdcardOutlined,
  type: 'group',
  children: [
    {
      id: 'Withdrawals',
      title: <FormattedMessage id="Withdrawals" />,
      type: 'item',
      url: '/transactions/withdrawals',
      icon: icons.BankOutlined
    },
    {
      id: 'Deposits',
      title: <FormattedMessage id="Deposits" />,
      type: 'item',
      url: '/transactions/deposits',
      icon: icons.BankOutlined
    },
    {
      id: 'Bets',
      title: <FormattedMessage id="Bets" />,
      type: 'item',
      url: '/transactions/bets',
      icon: icons.DatabaseOutlined
    },
    {
      id: 'Wins',
      title: <FormattedMessage id="Wins" />,
      type: 'item',
      url: '/transactions/wins',
      icon: icons.DatabaseOutlined
    },
    {
      id: 'Positions',
      title: <FormattedMessage id="Positions" />,
      type: 'item',
      url: '/transactions/positions',
      icon: icons.DatabaseOutlined
    },
    {
      id: 'Transactions',
      title: <FormattedMessage id="Transactions" />,
      type: 'item',
      url: '/transactions/transactions',
      icon: icons.DatabaseOutlined
    }
  ]
};

export default transactions;
