// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UserOutlined, BankOutlined, IdcardOutlined, DatabaseOutlined } from '@ant-design/icons';

// icons
const icons = {
  UserOutlined,
  BankOutlined,
  IdcardOutlined,
  DatabaseOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const users = {
  roles: ['customer-support', 'admin'],
  id: 'group-support',
  title: <FormattedMessage id="Support" />,
  icon: icons.UserOutlined,
  type: 'group',
  children: [
    {
      id: 'SupportUsers',
      title: <FormattedMessage id="Users" />,
      type: 'item',
      url: '/support/users',
      icon: icons.UserOutlined
    },
    {
      id: 'SupportWithdrawals',
      title: <FormattedMessage id="Withdrawals" />,
      type: 'item',
      url: '/support/withdrawals',
      icon: icons.BankOutlined
    },
    {
      id: 'KYCs',
      title: <FormattedMessage id="KYCs" />,
      type: 'item',
      url: '/support/kycs',
      icon: icons.IdcardOutlined
    },
    {
      id: 'Promotions',
      title: <FormattedMessage id="Promotions" />,
      type: 'item',
      url: '/support/promotions',
      icon: icons.DatabaseOutlined
    },
    {
      id: 'Freespins',
      title: <FormattedMessage id="Freespins" />,
      type: 'item',
      url: '/support/freespins',
      icon: icons.DatabaseOutlined
    }
  ]
};

export default users;
