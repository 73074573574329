// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { LineChartOutlined, IdcardOutlined, DatabaseOutlined } from '@ant-design/icons';

// icons
const icons = {
  LineChartOutlined,
  IdcardOutlined,
  DatabaseOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const permissions = {
  roles: ['admin'],

  id: 'group-permissions',
  title: <FormattedMessage id="Permissions" />,
  icon: icons.DatabaseOutlined,
  type: 'group',
  children: [
    {
      id: 'team',
      title: <FormattedMessage id="Team" />,
      type: 'item',
      url: '/permissions/team',
      icon: icons.DatabaseOutlined
    },
    {
      id: 'logs',
      title: <FormattedMessage id="Logs" />,
      type: 'item',
      url: '/permissions/logs',
      icon: icons.DatabaseOutlined
    }
  ]
};

export default permissions;
