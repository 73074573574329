// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DatabaseOutlined } from '@ant-design/icons';

// icons
const icons = {
  DatabaseOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const users = {
  roles: ['admin'],
  id: 'group-Casino',
  title: <FormattedMessage id="Casino" />,
  icon: icons.DatabaseOutlined,
  type: 'group',
  children: [
    {
      id: 'Games',
      title: <FormattedMessage id="Games" />,
      type: 'item',
      url: '/casino/games',
      icon: icons.DatabaseOutlined
    },
    {
      id: 'Providers',
      title: <FormattedMessage id="Providers" />,
      type: 'item',
      url: '/casino/providers',
      icon: icons.DatabaseOutlined
    }
  ]
};

export default users;
